import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取文件集合
 * @param {Object} data
 */
export function getFileList (data) {
  return request({
    url: '/ooh-statement/v1/lifeBillAttach/getFileList',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 删除单-多个文件
 * @param {Object} data
 */
export function deleteFiles (data) {
  return request({
    url: '/ooh-statement/v1/lifeBillAttach/deleteFiles',
    method: 'post',
    data: qs.stringify(data)
  })
}
